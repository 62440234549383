<template>
  <div class="main-container">
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="mainForm.rules" label-width="200px">
        <el-form-item :label="$t('form.activity.isOnline')" prop="online">
          <el-switch v-model="mainForm.data.online" />
        </el-form-item>
        <el-form-item :label="$t('form.activity.homePageDisplay')" prop="homepage_display">
          <el-switch v-model="mainForm.data.homepage_display" />
        </el-form-item>
        <el-form-item :label="$t('form.activity.activityName')" prop="name">
          <el-input v-model="mainForm.data.name" :placeholder="$t('placeholder.defaultInput')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('form.activity.activityNameEN')" prop="name_en">
          <el-input v-model="mainForm.data.name_en" :placeholder="$t('placeholder.defaultInput')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('form.activity.activityType')" prop="activity_type">
          <el-select v-model="mainForm.data.activity_type" :placeholder="$t('placeholder.defaultSelect')" disabled @change="handleMainFormActivityTypeSelectChange">
            <el-option v-for="(item, index) in activityTypeList" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('form.activity.activityTime')" prop="date">
          <el-date-picker v-model="mainForm.data.date" type="datetimerange" range-separator="-" :start-placeholder="$t('form.activity.startTime')" :end-placeholder="$t('form.activity.endTime')" @change="handleActivityDateChange"/>
        </el-form-item>
        <el-form-item :label="$t('form.activity.activityCover')">
          <importImg :limit="1" :data="mainForm.cover" @change="handleMainFormAcivityCoverChange" />
        </el-form-item>
        <div v-if="mainForm.data.activity_type === dataDictionary['001']['007'].code">
          <el-form-item :label="$t('form.activity.deliveryTime')" prop="delivery_date">
            <el-date-picker v-model="mainForm.data.delivery_date" type="daterange" range-separator="-" :start-placeholder="$t('form.activity.startTime')" :end-placeholder="$t('form.activity.startTime')" :disabled="deliveryDateDisabled" />
          </el-form-item>
        </div>
        <el-form-item v-if="mainForm.data.activity_type === dataDictionary['001']['004'].code" :label="$t('form.activity.flashSaleSessions')">
          <div v-for="(item, index) in shopActivitySessionsAttributesFilter" :key="index">
            <div v-if="item._destroy !== true" >
              <el-row class="clearf">
                <el-col class="fl">
                  <el-form-item :prop="`shop_activity_sessions_attributes.${index}.date`" :label="`${$t('form.activity.sessions') + (index + 1)}`" style="display: inline-block; margin-bottom: 0" label-width="80px">
                    <el-date-picker v-model="item.date" type="datetimerange" :disabled="item.disabled" class="interval-mr" range-separator="-" :start-placeholder="$t('form.activity.startTime')" :end-placeholder="$t('form.activity.endTime')" />
                  </el-form-item>
                  <el-button type="danger" icon="el-icon-delete" @click="() => handleMainFormFlashSaleItemDelClick(item, index)">{{ $t('form.activity.remove') }}</el-button>
                </el-col>
                </el-row>
              <el-divider />
            </div>
          </div>
          <el-button @click="handleMainFormAddFlashSaleClick">{{ $t('form.activity.addFlashSaleSessions') }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import utils from '@/utils/Utils'
import dataDictionary from '@/utils/data-dictionary.js'
import loading from '@/utils/loading.json'
import importImg from '@/components/importImg.vue'
import * as promotionApi from '@/services/promotion.js'

export default {
  components: {
    importImg
  },
  data () {
    return {
      dataDictionary,
      urlParams: null,
      activityTypeList: [],
      delivery_start_date: null,
      deliveryDateDisabled: false,
      mainForm: {
        cover: [],
        rules: {
          online: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          homepage_display: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          name: [
            { required: false, message: this.$t('text.required'), trigger: 'blur' }
          ],
          name_en: [
            { required: false, message: this.$t('text.required'), trigger: 'blur' }
          ],
          activity_type: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          date: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          delivery_date: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ]
        },
        data: {
          online: true, // 上架
          homepage_display: false, // 是否首页展示
          name: null, // 活动动名
          name_en: null, // 活动名(英文)
          activity_type: null, // 活动类型
          rank: undefined,
          date: null,
          begin_date: null, // 开始时间
          end_date: null, // 结束时间
          delivery_date: [],
          remote_image_url: null,
          shop_activity_sessions_attributes: []
        }
      }
    }
  },
  computed: {
    shopActivitySessionsAttributesFilter () {
      const _this = this
      const data = _this.mainForm.data.shop_activity_sessions_attributes
      return data.filter(item => item._destroy !== true)
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    const initRequset1 = promotionApi.getShopActivitiesIdPreview(_this.urlParams.id).then(res => {
      const resdata = res.data.shop_activity
      const mainFormData = _this.mainForm.data
      mainFormData.online = resdata.online
      mainFormData.homepage_display = resdata.homepage_display
      mainFormData.name = resdata.name
      mainFormData.name_en = resdata.name_en
      mainFormData.begin_date = resdata.begin_date
      mainFormData.end_date = resdata.end_date
      mainFormData.date = [resdata.begin_date, resdata.end_date]
      if (resdata.delivery_start_date) {
        mainFormData.delivery_date[0] = resdata.delivery_start_date
      }
      if (resdata.delivery_end_date) {
        mainFormData.delivery_date[1] = resdata.delivery_end_date
      }
      mainFormData.activity_type = resdata.activity_type
      // mainFormData.delivery_date = resdata.delivery_date
      mainFormData.remote_image_url = resdata.image_url
      if (resdata.shop_activity_sessions && resdata.shop_activity_sessions.length > 0) {
        mainFormData.shop_activity_sessions_attributes = resdata.shop_activity_sessions.map(item => {
          return {
            id: item.id,
            date: [item.begin_time, item.end_time],
            disabled: true,
            _destroy: false
          }
        })
      }
      _this.mainForm.data = mainFormData
      _this.mainForm.cover = [{ id: Date.now(), image_url: resdata.image_url }]
      _this.handleActivityDateChange()
      return res
    }).catch(err => err)
    const initRequset2 = promotionApi.getShopActivitiesActivityTypes().then(res => {
      _this.activityTypeList = res.data
      return res
    }).catch(err => err)
    _this.setGlobalLoading(true)
    Promise.all([initRequset1, initRequset2]).then(() => _this.setGlobalLoading(false)).catch(() => _this.setGlobalLoading(false))
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainFormAcivityCoverChange (fileData) {
      const _this = this
      if (fileData && fileData.length > 0) {
        _this.mainForm.data.remote_image_url = fileData[0].url
      } else {
        _this.mainForm.data.remote_image_url = null
      }
    },
    handleActivityDateChange () {
      const _this = this
      if (_this.mainForm.data.date !== null &&  new Date().getTime() <= new Date(_this.mainForm.data.date[0]).getTime()) {
        _this.deliveryDateDisabled = false
      } else {
        _this.deliveryDateDisabled = true
      }
    },
    handleMainFormAddFlashSaleClick () {
      const _this = this
      _this.mainForm.data.shop_activity_sessions_attributes.push({
        date: null
      })
    },
    handleMainFormFlashSaleItemDelClick (data, index) {
      const _this = this
      if (data._destroy !== undefined) {
        _this.mainForm.data.shop_activity_sessions_attributes[index]._destroy = true
      } else {
        _this.mainForm.data.shop_activity_sessions_attributes.splice(index, 1)
      }
    },
    handleMainFormActivityTypeSelectChange () {
      const _this = this
      _this.mainForm.data.shop_activity_sessions_attributes = []
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          const mainFormData = _this.mainForm.data
          if (mainFormData.activity_type === dataDictionary['001']['004'].code) {
            if (mainFormData.shop_activity_sessions_attributes.length < 1) {
              _this.$message({
                type: 'warning',
                message: this.$t('请增加场次')
              })
              return false
            } else {
              for (let i = 0; i < mainFormData.shop_activity_sessions_attributes.length; i++) {
                const item = mainFormData.shop_activity_sessions_attributes[i]
                if (!item.date) {
                  _this.$message({
                    type: 'warning',
                    message: _this.$t('请填写完整场次信息')
                  })
                  return false
                }
              }
            }
          }
          const requestParams = {
            shop_activity: {
              ..._this.mainForm.data
            }
          }
          requestParams.shop_activity.begin_date = requestParams.shop_activity.date[0]
          requestParams.shop_activity.end_date = requestParams.shop_activity.date[1]
          if (requestParams.shop_activity.activity_type === dataDictionary['001']['007'].code) {
            requestParams.shop_activity.delivery_start_date = utils.dateFormat('YYYY-mm-dd', requestParams.shop_activity.delivery_date[0])
            requestParams.shop_activity.delivery_end_date = utils.dateFormat('YYYY-mm-dd', requestParams.shop_activity.delivery_date[1])
          }
          if (requestParams.shop_activity.activity_type === dataDictionary['001']['004'].code) {
            requestParams.shop_activity.shop_activity_sessions_attributes.forEach(item => {
              item.begin_time = item.date[0]
              item.end_time = item.date[1]
              delete item.date
            })
          }
          _this.setGlobalLoading(true)
          promotionApi.putShopActivities(_this.urlParams.id, requestParams).then(res => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.go(-1)
            }, 800)
          }).catch(() => _this.setGlobalLoading(false))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .interval-mr {
    margin-right: 12px;
  }
  .clearf::after {
    content: '';
    display: block;
    clear: both;
  }
  .fl {
    float: left;
  }
}
</style>
